<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF aC cursor-pointer" @click="$router.go(-1)">
			<a-icon type="arrow-left" class="mr-3" />
			{{ selectedBroadcast.name }}
		</div>
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
					<div class="dF aC" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>

					<DatePicker @current-times-update="fetchChartData($event)" :disabled-compare-date="true" />
				</div>
				<a-tab-pane key="1" :tab="'Overview'">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: 5,
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="loading">
								<BhLoading :show="loading" />
							</div>
							<div v-if="!error" class="mb-5">
								<a-card :bordered="false" :loading="loading">
									<div data-html2canvas-ignore="true" class="dF aC jE">
										<span class="mr-2">Chart Type:</span>
										<a-select v-model="chartType" style="width: 80px" class="text-primary"
											:showArrow="true">
											<a-icon slot="suffixIcon" type="caret-down" class="text-primary" />
											<a-select-option value="bar" class="text-primary">
												Bar
											</a-select-option>
											<a-select-option value="line" class="text-primary">
												Line
											</a-select-option>
										</a-select>
									</div>
									<eChartLine :option="chartOptions" />
								</a-card>
							</div>
							<div v-else class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="fetchChartData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import BhLoading from 'bh-mod/components/common/Loading'
import eChartLine from "@/components/charts/eChartLine";
import VueHtml2pdf from "vue-html2pdf";
import DatePicker from "@/components/charts/datePicker.vue";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import { mapState, mapMutations } from "vuex";
import moment from "moment";

export default {
	components: {
		BhLoading,
		eChartLine,
		VueHtml2pdf,
		DatePicker,
		PDFOrientation,
	},
	data() {
		return {
			loading: false,
			error: false,
			broadcastData: [],
			chartType: 'bar',
			basicOptions: {
				title: {
					text: '',
					left: 'center'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					top: "bottom"
				},
				xAxis: {
					type: 'category',
					data: []
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: 'Sent',
						type: 'bar',
						data: []
					},
					{
						name: 'Delivered',
						type: 'bar',
						data: []
					}
				]
			}
		}
	},
	computed: {
		...mapState([
			"currentStartDate",
			"currentEndDate",
		]),

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		selectedBroadcast() {
			return this.$store.state.selectedBroadcast || {}
		},

		filename() {
			return `${this.selectedBroadcast.name || 'SMS Broadcast Report'} (${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")})`;
		},

		chartOptions() {
			let options = JSON.parse(JSON.stringify(this.basicOptions));
			options.title.text = 'Broadcast Delivery Report';
			options.tooltip.trigger = this.chartType === 'pie' ? 'item' : 'axis'
			this.broadcastData.map((item) => {
				options.series[0].type = this.chartType;
				options.series[1].type = this.chartType;

				options.xAxis.data.push(moment(item.date).format('ll'));
				options.series[0].data.push(item.sentCount)
				options.series[1].data.push(item.deliveredCount)
			})

			return options;
		},
	},
	methods: {
		moment,
		...mapMutations(["setCurrentDates", "setCompareDates"]),

		async fetchChartData({ startDate = this.currentStartDate, endDate = this.currentEndDate }, dateType = "currentData") {
			this.loading = true;
			this.error = false;
			if (dateType === "currentData") {
				this.setCurrentDates({ startDate, endDate });
			} else {
				this.setCompareDates({ startDate, endDate });
			}
			try {
				const { data } = await this.$api.get(`sms-broadcasts/:instance/${this.$route.params.id}/delivery-report?start=${startDate}&end=${endDate}`);
				this.broadcastData = data && data.deliveryReport || [];
			} catch (err) {
				this.error = true;
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while getting broadcast report. Please try again.`))
				}
			}
			this.loading = false;
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},

		getCSV() {
			this.$nprogress.start();
			fetch(`/sms-broadcasts/:instance/${this.$route.params.id}/csv`, {
				headers: {
					'Content-Type': 'text/csv'
				},
				responseType: 'blob'
			}).then(response => response.blob()).then(blob => {
				var a = window.document.createElement("a");
				a.href = window.URL.createObjectURL(blob, {
					type: "text/csv"
				});
				a.download = `${this.selectedBroadcast.name}_${Date.now()}.csv`
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				this.$nprogress.done();
			}).catch((err) => {
				this.$message.error(this.$err(err, `An error occurred while downloading broadcast csv data. Please try again.`))
				this.$nprogress.done();
			});
		},

		async fetchBroadcastDetails() {
			try {
				const { data } = await this.$api.get(`sms-broadcasts/:instance/${this.$route.params.id}`)
				this.$store.commit('SET_BROADCAST', data)
			} catch (err) {
				console.error(err);
			}
		}
	},
	created() {
		if ((!this.selectedBroadcast || !this.selectedBroadcast.name) && this.$route.params.id) {
			this.fetchBroadcastDetails()
		}
	},
};
</script>

<style>
</style>
